import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import styles from './Reviews.module.scss';
import type { FC } from 'react';
import Slide from '#components/ui/Reviews/Slide';

type Props = {
  expandLabel: string;
  items: Array<{
    author: string;
    comment: string;
  }>;
};

const Slider: FC<Props> = ({ items, expandLabel }) => {
  return (
    <>
      <Swiper
        className={styles.slider}
        slidesPerView={1}
        spaceBetween={16}
        loop={false}
        modules={[Navigation, Pagination]}
        pagination={{
          el: `.${styles.pagination}`,
          bulletClass: styles.slide_bullet,
          bulletActiveClass: styles.selected_bullet,
          clickable: true,
        }}
        breakpoints={{
          [1169]: {
            spaceBetween: 24,
            slidesPerView: 3,
            slidesPerGroup: 3,
            allowTouchMove: false,
          },
        }}
        navigation={{
          prevEl: `.${styles.prev_slide}`,
          nextEl: `.${styles.next_slide}`,
          disabledClass: styles.disabled_nav,
        }}
      >
        {items.map((item, idx) => (
          <SwiperSlide key={idx} className={styles.slide}>
            <Slide {...item} expandLabel={expandLabel} />
          </SwiperSlide>
        ))}

        <div className={styles.navigation}>
          <div className={`${styles.slide_nav} ${styles.prev_slide}`}></div>
          <div className={`${styles.pagination}`}></div>
          <div className={`${styles.slide_nav} ${styles.next_slide}`}></div>
        </div>
      </Swiper>
    </>
  );
};

export default Slider;
