import styles from './Reviews.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import Typography from '#components/ui/Typography';
import Caret from '#icons/Caret';

type Props = {
  author: string;
  comment: string;
  expandable?: boolean;
  expandLabel: string;
};

const Slide: FC<Props> = ({ author, comment, expandable, expandLabel }) => {
  const [expanded, setExpanded] = useState(false);
  const [isExpandable, setIsExpanded] = useState(expandable);
  const textRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!textRef.current) {
      return;
    }
    const paragraph = textRef.current.querySelectorAll('p')[1];
    if (paragraph.clientHeight > 109) setIsExpanded(true);
  }, [textRef]);

  return (
    <div className={styles.review} ref={textRef}>
      <Typography className={styles.author} component="p" variant="subtitle1" color="primary">
        {author}
      </Typography>
      <div className={styles.rating}></div>
      <Typography
        className={isExpandable && !expanded ? styles.elipsis : ''}
        component="p"
        variant="body2"
        color="secondary"
      >
        {comment}
      </Typography>
      {isExpandable && !expanded && (
        <>
          <div className={styles.expand} onClick={() => setExpanded(true)}>
            <Typography
              className={styles.show_more}
              component="p"
              variant="subtitle1"
              color="green"
            >
              {expandLabel}
            </Typography>
            <Caret />
          </div>
        </>
      )}
    </div>
  );
};

export default Slide;
